/* eslint-disable no-undef */
/* eslint-disable block-scoped-var */
/* eslint-disable vars-on-top */
/* eslint-disable one-var */
/* eslint-disable no-magic-numbers */
/* eslint-disable quote-props */
/* eslint-disable max-statements */

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

window.scriptsLocation = '/scripts/';
const scripts = document.getElementsByTagName('script');

for (let idx = 0; idx <= scripts.length; idx = idx + 1) {
  if (scripts[idx].src.indexOf('public.min.js') > -1) {
    window.scriptsLocation = scripts[idx].src.replace('public.min.js', '');
    break;
  }
}
window.cssLocation = window.scriptsLocation.replace('/scripts/', '/assets/css/');

window.public = Object;

window.public.addFonts = function(filename) {
  'use strict';
  var zero = 0,
      fileref = document.createElement('link');

  fileref.setAttribute('rel', 'stylesheet');
  fileref.setAttribute('type', 'text/css');
  fileref.setAttribute('href', window.cssLocation + filename);
  document.getElementsByTagName('head')[zero].appendChild(fileref);
};

window.public.addScript = function(script) {
  'use strict';
  var zero = 0,
      head = document.getElementsByTagName('head')[zero],
      addIt = document.createElement('script');

  addIt.type = 'text/javascript';
  addIt.src = window.scriptsLocation + script;

  head.appendChild(addIt);
  return true;
};

window.public.additions = function() {
  'use strict';
  var zero = 0;
  const uiSpecials = [ 'aiepkorting',
                       'switch-tab',
                       'switch-btn',
                       'js-modal',
                       'js-scrollshadow',
                       'js-filter__action'
                     ],
        animations = [],
        video = ['js-ytv'];

  uiSpecials.some(function(item) {
    if (document.getElementsByClassName(item).length > zero) {
      return window.public.addScript('ui-specials.min.js');
    }
    return false;
  });
  animations.some(function(item) {
    if (document.getElementsByClassName(item).length > zero) {
      return window.public.addScript('animations.min.js');
    }
    return false;
  });
  video.some(function(item) {
    if (document.getElementsByClassName(item).length > zero) {
      return window.public.addScript('video.min.js');
    }
    return false;
  });
};
window.public.components = function() {
  'use strict';
  var zero = 0;
  const scriptsByClassName = [
        {sid: 'cyberscan',
          url: 'cyberscan.min.js'},
        {sid: 'js-validator',
          url: 'validator.min.js'},
        {sid: 'js-keuzehulp',
          url: 'keuzehulp.min.js'},
        {sid: 'js-barometer-form',
          url: 'forms.min.js'}

  ],
  scriptsByID = [
        {sid: 'leaflet',
          url: 'leaflet.min.js'}
  ];

  /* add tags script */
  if (document.querySelector('[data-tags-show]') && 
      !document.querySelector('body.mijninterpolis')) {
    window.public.addScript('tags-show.min.js')
  }
  // Checks on ID first. These are faster.
  for (var iitem in scriptsByID) {
    if (document.getElementById(scriptsByID[iitem].sid)) {
      window.public.addScript(scriptsByID[iitem].url);
    }
  }
  /* Checks on ClassName */
  for (var citem in scriptsByClassName) {
    if (document.getElementsByClassName(scriptsByClassName[citem].sid).length > zero) {
      window.public.addScript(scriptsByClassName[citem].url);
    }
  }
};
/* Providing globaly available method for checks */
window.checks = Object;
window.checks.editMode = function() {
  'use strict';
  // eslint-disable-next-line max-len
  return Boolean(typeof Sitecore !== 'undefined' && Sitecore && Sitecore.PageModes && Sitecore.PageModes.PageEditor);
};
(function() {
  'use strict';
  var zero = 0;

  if (document.readyState === 'loading') {
    window.addEventListener('DOMContentLoaded', function() {
      window.public.addScript('core.min.js');
      window.public.additions();
      window.public.components();
    });
  } else {
    window.public.addScript('core.min.js');
    window.public.additions();
    window.public.components();
  }
})();
